/*noinspection CssUnknownTarget*/
@import "@genstackio/react-admin-core/assets/css/styles.css";

.theme-killbills {
    --color-clear: 255, 255, 255;/* #FFFFFF */
    --color-danger: 166, 67, 55;/* #A64337 */
    --color-dark: 28, 26, 26;/* #1C1A1A */
    --color-disabled: 206, 204, 195;/* #CECCC3 */
    --color-info: 47, 103, 88;/* #C8C8C8 */
    --color-light: 243, 243, 243;/* #F3F3F3 */
    --color-primary: 41, 37, 59;/* #37A76F */
    --color-default: 120, 242, 160;/* #37A76F */
    --color-secondary: 39, 40, 122;/* #27287A */
    --color-success: 223, 238, 222;/* #DFEEDE */
    --color-warning: 199, 166, 24;/* #C7A618 */
}
.custom-tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    z-index: 9999;
    /* Add any additional styling as needed */
  }
  .fc-daygrid-day-bottom {
    display: none;
  }
  .fc-h-event{
    border: unset !important;
    background-color: unset !important;
  }
  .fc .fc-daygrid-event-harness-abs{
    right: unset !important;
  }
  .fc-h-event .fc-event-main{

    color:unset;
  }